<template>
    <div class="container">


        <div class="teaching-load-form mt-4">
            <h4 style="text-align: center">Педагогическая нагрузка</h4>
            <p style="text-align: center">{{getDepartmentId}}</p>


            <!--                        <DataTable :value="teachingLoad_form.curriculums" :paginator="true" :rows="10"-->
            <!--                                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"-->
            <!--                                   :rowsPerPageOptions="[10,20,50]"-->
            <!--                                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"-->
            <!--                                   stripedRows responsiveLayout="scroll">-->

            <!--                          <Column field="education_speciality_code" header="Код"></Column>-->
            <!--                          <Column field="education_speciality_name" header="Название ОП"></Column>-->
            <!--                          <Column field="decreeGosoTemplate.decree" header="Название приказа ГОСО"></Column>-->
            <!--                          <Column field="language.native_name" header="Язык обучения"></Column>-->


            <!--                        </DataTable>-->


            <table class="table table-striped table-bordered">
                <thead>

                <tr>
                    <th scope="col">Дисциплина</th>
                    <th scope="col">Образовательная программа</th>
                    <th scope="col">Язык обучения</th>
                    <th scope="col">Кредит</th>
                    <th scope="col">Курс</th>
                    <th scope="col">Семестр</th>
                    <th scope="col">Количество студентов</th>
                    <th scope="col">Количество групп</th>
                    <th scope="col">Количество потоков</th>
                    <th scope="col">Лекции</th>
                    <th scope="col">Практика</th>
                    <th scope="col">Офисные часы</th>
                    <th scope="col">ВСК, экзамен</th>
                    <th scope="col">Преподаватели</th>
                </tr>
                </thead>

                <tbody>

                <template v-for="(curriculum, index) in teachingLoad_form.curriculums" :key="index">
                    <tr v-for="(formation, formationIndex) in curriculum.educationProgram.formationEducationPrograms"
                        :key="'formationIndex'+formationIndex">
                        <td>
                            {{formation.educationDiscipline.name}}
                        </td>
                        <td>
                            {{curriculum.educationProgram.education_speciality_name}}
                        </td>
                        <td>
                            {{curriculum.educationProgram.language.native_name}}
                        </td>
                        <td>
                            {{formation.credit}}
                        </td>
                        <td>
                            {{curriculum.study_course}}
                        </td>
                        <td>
                            {{formation.semester}}
                        </td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td></td>
                        <td>
                            {{formation.educationDiscipline.teacherCourses.length?formation.educationDiscipline.teacherCourses.map(i=>i.pps.lastname+
                            ' '+i.pps.firstname).join(', '):''}}
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>


        </div>

    </div>
</template>

<script>
    import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
    import {getCookie} from "@/utils/helpers/cookies.helpers";

    export default {
        name: "TeachingLoad",

        data() {
            return {}
        },
        computed: {
            ...mapState('teachingLoad', ['teachingLoad_form']),
            getDepartmentId() {
                return getCookie('DEPARTMENT_ID')
            }
        },

        methods: {
            ...mapActions('teachingLoad', ['GET_CURRICULUM_DATA_BY_DEPARTMENT']),

        },
        async mounted() {
            // await this.GET_TEACHING_LOAD_BY_DEPARTMENT()
            await this.GET_CURRICULUM_DATA_BY_DEPARTMENT()
        }
    }
</script>

<style scoped>

</style>